import React, { useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import { useMutation, useQuery } from "@apollo/client";
import { useRouteMatch, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { toast } from "react-toastify";
import { QUERY_CLIENT_SENDGRID_TEMPLATES } from "../../../../config/graphql/query";
import { DELETE_CLIENT_SENDGRID_TEMPLATE } from "../../../../config/graphql/mutation";

import Table from "../../../../components/Table";

import { useClientId } from "../hooks/useClient";

const TableRow = ({
  item,
  index,
}: {
  item: IClientSendGridTemplate;
  index: number;
}) => {
  const { url } = useRouteMatch();

  const history = useHistory();

  const clientId = useClientId();

  const { t } = useTranslation(["client", "common"]);

  const { id, title, template, templateId } = item;

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_CLIENT_SENDGRID_TEMPLATE, {
    refetchQueries: [
      {
        query: QUERY_CLIENT_SENDGRID_TEMPLATES,
        variables: {
          filter: { client: clientId },
        },
      },
    ],
    variables: { input: { id, client: clientId } },
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const onBeforeDelete = () => {
    onDelete()
      .then(() => {
        toast.success<string>(
          t("client:client.sendgrid.template.toast.deleted"),
        );

        history.replace(`/clients/${clientId}/sendgrid-email-templates`);
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );

        history.replace(`/clients/${clientId}/sendgrid-email-templates`);
      });

    setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{title}</td>
        <td>{template}</td>
        <td>{templateId}</td>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`${url}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("client:client.sendgrid.modal.template.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:client.sendgrid.modal.template.body", { id })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const EmailTemplates = React.memo(() => {
  const { url } = useRouteMatch();

  const clientId = useClientId();

  const { t } = useTranslation(["client", "common"]);

  const { data } = useQuery(QUERY_CLIENT_SENDGRID_TEMPLATES, {
    fetchPolicy: "network-only",
    skip: !clientId,
    variables: {
      filter: {
        client: clientId,
      },
    },
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const sendGridTemplateArr = data?.emailTemplates ?? [];

  const renderClientToken = useCallback(
    (item: IClientSendGridTemplate, index: number) => (
      <TableRow key={item.id} item={item} index={index} />
    ),
    [],
  );

  return (
    <>
      <div className="d-flex justify-content-end align-items-center my-4">
        <div>
          <Link to={`${url}/new`} type="button" className="btn btn-primary">
            {t("client:client.sendgrid.template.button.add")}
          </Link>
        </div>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{t("client:client.sendgrid.template.th.title")}</th>
            <th scope="col">
              {t("client:client.sendgrid.template.th.template")}
            </th>
            <th scope="col">
              {t("client:client.sendgrid.template.th.templateid")}
            </th>
            <th scope="col">{t("client:client.tokens.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{sendGridTemplateArr.map(renderClientToken)}</tbody>
      </Table>
    </>
  );
});

export default EmailTemplates;
