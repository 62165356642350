import React, { useCallback, useMemo, useState } from "react";

import { useQuery } from "@apollo/client";
import { useDebounce } from "react-use";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";

import qs from "query-string";
import dayjs from "dayjs";
import get from "lodash/get";
import axios from "axios";

import Select from "react-select";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";

import Table from "../../../../components/Table";
import Pagination from "../../../../components/Pagination";
import {
  QUERY_ACCESS_EVENTS,
  QUERY_ACCESS_EVENT_TYPE_LIST,
} from "../../../../config/graphql/query";

import { reactSelectCustomStyles } from "../../../Employees/Employee/Information";
import { useCurrentClient } from "../../../../context/Client";

const limit = 10;

export type TAccessType =
  | "Employee"
  | "Visitor"
  | "MeetingAttendee"
  | "Package"
  | "Food"
  | "Guest"
  | "MmmrDriver"
  | "MmmrVisitor"
  | "MmmrAdditionalVisitor";

type TStatus = "checkIn" | "checkOut";

const statusOptions: { value: TStatus; label: string }[] = [
  { value: "checkIn", label: "Check in" },
  { value: "checkOut", label: "Check out" },
];

const TableRow = ({ item }: { item: IAccessEvent }) => {
  const { id, type, metadata, owner, receiver, customReceiver, createdAt } =
    item;

  const fullName = owner
    ? get(owner, "fullName") || get(owner, "guestFullName")
    : get(receiver, "fullName") || get(customReceiver, "fullName");
  const email = owner
    ? get(owner, "email") || get(owner, "guestEmail")
    : get(receiver, "email") || get(customReceiver, "email");

  let accessType = type;

  const isMaintenance = get(owner, "isMaintenance");

  if (isMaintenance) {
    accessType = "MmmrMaintenance";
  }

  const status = "checkOut" in metadata ? "Check out" : "Check in";

  const { t } = useTranslation(["accessEvent", "common"]);

  return (
    <>
      <tr>
        <td>{fullName}</td>
        <td>{email}</td>
        <td>
          {t(`accessEvent:type.${accessType}`)}
          {accessType === "Package" || accessType === "Food"
            ? ""
            : ` / ${status}`}
        </td>
        <td>{dayjs(createdAt).local().format("DD-MM-YYYY / HH:mm")}</td>
        <td className="text-nowrap">
          <div className="d-flex">
            <Link to={`/activity/accessEvents/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
    </>
  );
};

const AccessEvents = React.memo(() => {
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation(["accessEvent", "common"]);

  const client = useCurrentClient();

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        page?: number;
        search?: string;
        startDate?: Date;
        endDate?: Date;
        accessType?: TAccessType;
        status?: TStatus;
      },
    [location.search],
  );

  const [search, setSearch] = useState(query.search);

  const [startDate, setStartDate] = useState(
    query.startDate ? dayjs(query.startDate).toDate() : undefined,
  );
  const [endDate, setEndDate] = useState(
    query.endDate ? dayjs(query.endDate).toDate() : undefined,
  );

  const [accessType, setAccessType] = useState<TAccessType | undefined>(
    query.accessType,
  );
  const [status, setStatus] = useState<TStatus | undefined>(query.status);

  const page = useMemo(() => Math.max(query.page || 0, 1), [query.page]);

  const variables = useMemo(() => {
    return {
      pagination: {
        limit,
        skip: (page - 1) * limit,
      },
      filter: {
        ...((query.startDate || query.endDate) && {
          createdAt: {
            ...(query.startDate && {
              GTE: dayjs(query.startDate).hour(0).minute(0).second(0).format(),
            }),
            ...(query.endDate && {
              LTE: dayjs(query.endDate).hour(23).minute(59).second(59).format(),
            }),
          },
        }),
        ...(query.search && {
          owner: {
            fullName: query.search.toString(),
            email: query.search.toString(),
          },
        }),
        ...(query.accessType && {
          type: query.accessType,
        }),
        ...(query.status && {
          status: query.status,
        }),
      },
    };
  }, [
    page,
    query.startDate,
    query.endDate,
    query.search,
    query.accessType,
    query.status,
  ]);

  const { data } = useQuery<{
    accessEvents?: {
      events: Array<IAccessEvent>;
      eventsCount: number;
    };
  }>(QUERY_ACCESS_EVENTS, {
    fetchPolicy: "network-only",
    variables,
  });

  const accessEvents: Array<IAccessEvent> = data?.accessEvents?.events ?? [];
  const accessEventsCount: number = data?.accessEvents?.eventsCount ?? 0;

  const { data: accessEventTypeListData } = useQuery<{
    accessEventTypeList: TAccessType[];
  }>(QUERY_ACCESS_EVENT_TYPE_LIST);

  const accessEventTypeOptions = useMemo(
    () =>
      accessEventTypeListData?.accessEventTypeList.map((type) => ({
        value: type,
        label: type.split(/(?=[A-Z])/).join(" "),
      })) ?? [],
    [accessEventTypeListData],
  );

  const renderItem = useCallback(
    (item: IAccessEvent) => <TableRow key={item.id} item={item} />,
    [],
  );

  useDebounce(
    () => {
      history.push({
        search: qs.stringify({
          page: 1,
          ...(search && { search }),
          ...(startDate && {
            startDate: dayjs(startDate).format("YYYY-MM-DD"),
          }),
          ...(endDate && { endDate: dayjs(endDate).format("YYYY-MM-DD") }),
          ...(accessType && { accessType }),
          ...(status && { status }),
        }),
      });
    },
    500,
    [search, startDate, endDate, accessType, status],
  );

  const onExport = () => {
    const url = new URL(
      `${process.env.REACT_APP_API_URL}/client/${client?.id}/export/logs`,
    );

    if (startDate) {
      url.searchParams.append(
        "start",
        dayjs(startDate).hour(0).minute(0).second(0).toISOString(),
      );
    }

    if (endDate) {
      url.searchParams.append(
        "end",
        dayjs(endDate).hour(23).minute(59).second(59).toISOString(),
      );
    }

    if (accessType) {
      url.searchParams.append("accessType", accessType);
    }

    if (status) {
      url.searchParams.append("status", status);
    }

    if (search) {
      url.searchParams.append("search", search);
    }

    axios({
      method: "get",
      url: url.toString(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
      },
      responseType: "blob",
    })
      .then((response) => {
        const fileName = "export.csv";

        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");

        link.href = href;
        link.setAttribute("download", fileName);

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);

        URL.revokeObjectURL(href);
      })
      .catch(console.log);
  };

  return (
    <div className="container-fluid">
      <div className="d-flex flex-wrap justify-content-between mt-4">
        <form className="form-inline">
          <div className="mr-5 mb-4">
            <label htmlFor="search" className="sr-only">
              {t("common:search")}
            </label>
            <input
              id="search"
              type="search"
              className="form-control"
              placeholder={t("common:search")}
              value={search}
              onChange={({ target: { value } }) => {
                setSearch(value);
              }}
            />
          </div>
          <div className="d-flex mb-4 mr-0 mr-sm-5">
            <div className="d-flex mr-4">
              <div className="mr-2 d-flex align-items-center">
                {t("accessEvent:accessEvents.date.from")}
              </div>
              <DatePicker
                isClearable
                selected={startDate}
                onChange={(date: Date) => {
                  setStartDate(date);
                  if (
                    date.toDateString() === new Date().toDateString() ||
                    dayjs(date).isAfter(dayjs(endDate))
                  ) {
                    setEndDate(date);
                  }
                }}
                filterDate={(date) => dayjs() > dayjs(date)}
                placeholderText="Start Date"
                dateFormat="MM/dd/yyyy"
                className="form-control"
                todayButton="Today"
              />
            </div>
            <div className="d-flex">
              <div className="mr-2 d-flex align-items-center">
                {t("accessEvent:accessEvents.date.to")}
              </div>
              <DatePicker
                isClearable
                selected={endDate}
                onChange={(date: Date) => {
                  setEndDate(date);
                }}
                filterDate={(date) => {
                  if (!startDate) {
                    return dayjs() > dayjs(date);
                  }
                  return (
                    dayjs() > dayjs(date) && dayjs(date) >= dayjs(startDate)
                  );
                }}
                placeholderText="End Date"
                dateFormat="MM/dd/yyyy"
                className="form-control"
              />
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row">
            <div className="d-flex mb-4 mr-5">
              <label htmlFor="roles" className="mb-0 mr-2 align-self-center">
                {t("accessEvent:accessEvents.type.label")}
              </label>
              <div style={{ width: "13rem" }}>
                <Select
                  isClearable
                  options={accessEventTypeOptions}
                  value={accessEventTypeOptions.find(
                    (option) => option.value === accessType,
                  )}
                  onChange={(value) => {
                    setAccessType(value?.value);
                  }}
                  styles={reactSelectCustomStyles(false)}
                />
              </div>
            </div>
            <div className="d-flex mb-4">
              <label htmlFor="roles" className="mb-0 mr-2 align-self-center">
                {t("accessEvent:accessEvents.checkStatus.label")}
              </label>
              <div style={{ width: "13rem" }}>
                <Select
                  isClearable
                  options={statusOptions}
                  isDisabled={accessType === "Package" || accessType === "Food"}
                  value={statusOptions.find(
                    (option) => option.value === status,
                  )}
                  onChange={(value) => setStatus(value?.value)}
                  styles={reactSelectCustomStyles(false)}
                />
              </div>
            </div>
          </div>
        </form>
        <button
          type="button"
          className="btn btn-primary mb-4"
          onClick={onExport}
        >
          {t("accessEvent:accessEvents.export")}
        </button>
      </div>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th scope="col">{t("accessEvent:accessEvents.th.fullName")}</th>
            <th scope="col">{t("accessEvent:accessEvents.th.email")}</th>
            <th scope="col">{t("accessEvent:accessEvents.th.type")}</th>
            <th scope="col">{t("accessEvent:accessEvents.th.time")}</th>
            <th scope="col">{t("accessEvent:accessEvents.th.actions")}</th>
          </tr>
        </thead>
        <tbody>{accessEvents.map(renderItem)}</tbody>
      </Table>
      <Pagination documentsCount={accessEventsCount} limit={limit} />
    </div>
  );
});

export default AccessEvents;
