import React from "react";

import { Switch, useRouteMatch, Redirect } from "react-router-dom";

import Route from "../../components/Route";
import FileUploadProvider from "../../components/FileUpload";

import DigitalReception from "./DigitalReception";
import DigitalReceptions from "./DigitalReceptions";
import DigitalReceptionSchedule from "./DigitalReception/Settings/Schedules/Schedule";
import EntranceAgreementFile from "./DigitalReception/Settings/EntranceAgreementFile/index";

const DigitalReceptionsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route.Base exact path={path}>
        <DigitalReceptions />
      </Route.Base>
      <Route.Role exact path={`${path}/new`} roles={["ADMIN"]} redirect={path}>
        <DigitalReception />
      </Route.Role>
      <Route.Base exact path={`${path}/:screenId/settings/schedules/new`}>
        <DigitalReceptionSchedule />
      </Route.Base>
      <Route.Base
        exact
        path={`${path}/:screenId/settings/schedules/:scheduleId`}
      >
        <DigitalReceptionSchedule />
      </Route.Base>
      <Route.Role
        exact
        path={`${path}/:screenId/settings/entrance-agreements/:agreementId/files/new`}
        roles={["ADMIN"]}
        redirect={`${path}/:screenId/settings`}
      >
        <FileUploadProvider>
          <EntranceAgreementFile />
        </FileUploadProvider>
      </Route.Role>
      <Route.Role
        exact
        path={`${path}/:screenId/settings/entrance-agreements/:agreementId/files/:agreementFileId`}
        roles={["ADMIN"]}
        redirect={`${path}/:screenId/settings`}
      >
        <FileUploadProvider>
          <EntranceAgreementFile />
        </FileUploadProvider>
      </Route.Role>
      <Route.Base path={`${path}/:screenId`}>
        <DigitalReception />
      </Route.Base>

      <Redirect to={path} />
    </Switch>
  );
});

export default DigitalReceptionsRouter;
