import React, { useCallback, useEffect, useRef, useMemo } from "react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import useCopyToClipboard from "react-use/lib/useCopyToClipboard";

import { useParams, useHistory } from "react-router-dom";

import map from "lodash/map";
import set from "lodash/set";

import Select from "react-select";

import clsx from "clsx";
import { toast } from "react-toastify";

import { FormProvider, useForm, useWatch, Controller } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";

import Input from "../../../../components/Input";

import {
  QUERY_SCREENS,
  QUERY_DIGITAL_RECEPTION,
  QUERY_DIGITAL_RECEPTION_TEMPLATES,
  QUERY_DIGITAL_RECEPTION_LANGUAGES,
} from "../../../../config/graphql/query";

import {
  CREATE_DIGITAL_RECEPTION,
  MUTATION_GENERATE_DIGITAL_RECEPTION_CODE,
  UPDATE_DIGITAL_RECEPTION,
} from "../../../../config/graphql/mutation";

import RoleBlock from "../../../../components/RoleBlock";

import { reactSelectCustomStyles } from "../../../Employees/Employee/Information";

interface FieldValues {
  title: string;
  template: string;
  language?: string;
  languages?: string[];
}

const DefaultLanguage = ({
  onChangePreview,
}: {
  onChangePreview: (name: string, value: any) => void | undefined;
}) => {
  const { t } = useTranslation(["screens", "common"]);

  const availableLanguages = useWatch<FieldValues, "languages">({
    name: "languages",
  });

  return (
    <Controller
      name="language"
      render={({
        field: { value: inputValue, onChange, ...field },
        fieldState: { error },
      }) => {
        const options = map(availableLanguages, (value) => ({
          value,
          label: t(`languages:${value}`),
        }));

        const value = !inputValue
          ? undefined
          : {
              value: inputValue,
              label: t(`languages:${inputValue}`),
            };

        return (
          <div className="form-group">
            <label htmlFor="type">
              {t("screens:screen.screenRoute.form.language")}
            </label>
            <Select
              {...field}
              value={value}
              options={options}
              className={clsx({
                "is-invalid": !!error,
              })}
              styles={reactSelectCustomStyles(!!error)}
              onChange={(option) => {
                onChange(option?.value);
                onChangePreview("language", option?.value);
              }}
            />
            {!!error && (
              <div className="invalid-feedback">{error?.message}</div>
            )}
          </div>
        );
      }}
    />
  );
};

const Information = React.memo(() => {
  const { screenId } = useParams<{ screenId: string }>();

  const [, copyToClipboard] = useCopyToClipboard();

  const history = useHistory();

  const previewRef = useRef<HTMLIFrameElement>(null);

  const { t } = useTranslation(["screens", "common"]);

  const postMessage = useCallback((message: string, targetOrigin = "*") => {
    return previewRef?.current?.contentWindow?.postMessage(
      message,
      targetOrigin,
    );
  }, []);

  const onChangePreview = (name: string, value: any) => {
    const data = {};

    set(data, ["digitalReception", ...name.split(".")], value);

    const message = JSON.stringify({
      action: "update-digitalReception",
      data,
    });

    return postMessage(message);
  };

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t("screens:screen.screenRoute.yup.title")),
        template: yup
          .string()
          .required(t("screens:screen.screenRoute.yup.template")),
        languages: yup
          .array()
          .of(yup.string())
          .required(t("screens:screen.screenRoute.yup.languages")),
        language: yup
          .string()
          .required(t("screens:screen.screenRoute.yup.language.required"))
          .test(
            "languagesToCheck",
            t("screens:screen.screenRoute.yup.language.oneOf"),
            function (value) {
              if (
                this.parent.languages &&
                this.parent.languages.includes(value)
              ) {
                return true;
              }
              return false;
            },
          ),
      }),
    [t],
  );

  const methods = useForm<FieldValues>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {
      template: "digitalreception",
    },
  });

  const { data: templatesData } = useQuery<{
    digitalReceptionTemplates: string[];
  }>(QUERY_DIGITAL_RECEPTION_TEMPLATES, {
    onError: () => history.replace("/digital-receptions"),
  });

  const { data: screenData } = useQuery<{
    digitalReception: IDigitalReception;
  }>(QUERY_DIGITAL_RECEPTION, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onError: () => history.replace("/digital-receptions"),
  });

  const template = methods.watch("template");

  const { data: languagesData } = useQuery<{
    digitalReceptionLanguages: string[];
  }>(QUERY_DIGITAL_RECEPTION_LANGUAGES, {
    variables: { template },
    skip: !template,
  });

  useEffect(() => {
    methods.reset(screenData?.digitalReception);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenData]);

  const refetchQueries = [
    {
      query: QUERY_SCREENS,
      variables: {
        filter: { type: { EQ: "DigitalReception" } },
        sort: { title: "ASC" },
      },
    },
  ];

  const [onCreate] = useMutation<{ createDigitalReception: IDigitalReception }>(
    CREATE_DIGITAL_RECEPTION,
    {
      refetchQueries,
    },
  );

  const [onUpdate] = useMutation<{ updateDigitalReception: IDigitalReception }>(
    UPDATE_DIGITAL_RECEPTION,
    {
      refetchQueries,
    },
  );

  const [onMutationGenerateScreenCode] = useMutation<{
    generateCode: IDigitalReception;
  }>(MUTATION_GENERATE_DIGITAL_RECEPTION_CODE, {
    refetchQueries,
  });

  const onSubmit = useCallback(
    (values: FieldValues) => {
      if (screenId) {
        return onUpdate({ variables: { input: { ...values, id: screenId } } })
          .then(() => {
            toast.success<string>(
              t("screens:screen.screenRoute.toast.updated"),
            );
          })
          .catch((error) => {
            toast.error<string>(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message,
            );
          });
      }

      return onCreate({ variables: { input: values } })
        .then(({ data }) => {
          history.replace(
            `/digital-receptions/${data?.createDigitalReception?.id}`,
          );

          toast.success<string>(t("screens:screen.screenRoute.toast.created"));
        })
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [screenId, onCreate, onUpdate, t, history],
  );

  return (
    <FormProvider {...methods}>
      <div className="tab-pane active" role="tabpanel">
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("screens:screen.screenRoute.form.title")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <RoleBlock roles={["ADMIN"]}>
              <div className="form-group">
                <label htmlFor="type">
                  {t("screens:screen.screenRoute.form.template")}
                </label>
                <select
                  {...methods.register("template")}
                  className="custom-select"
                >
                  {map(templatesData?.digitalReceptionTemplates, (value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </RoleBlock>
            <Controller
              name="languages"
              render={({
                field: { value: inputValue, onChange, ...field },
                fieldState: { error },
              }) => {
                const options = map(
                  languagesData?.digitalReceptionLanguages,
                  (value) => ({
                    value,
                    label: t(`languages:${value}`),
                  }),
                );

                const value = map(inputValue, (value) => ({
                  value,
                  label: t(`languages:${value}`),
                }));

                return (
                  <div className="form-group">
                    <label htmlFor="type">
                      {t("screens:screen.screenRoute.form.languages")}
                    </label>
                    <Select
                      {...field}
                      isMulti
                      closeMenuOnSelect={false}
                      isClearable={false}
                      value={value}
                      options={options}
                      className={clsx({
                        "is-invalid": !!error,
                      })}
                      styles={reactSelectCustomStyles(!!error)}
                      onChange={(options) => {
                        onChange(map(options, "value"));
                        onChangePreview("languages", map(options, "value"));
                      }}
                    />
                    {!!error && (
                      <div className="invalid-feedback">{error?.message}</div>
                    )}
                    {template === "digitalreception" && (
                      <small
                        id="languages-help"
                        className="form-text text-muted"
                      >
                        {t("screens:screen.screenRoute.form.languages-help")}
                      </small>
                    )}
                  </div>
                );
              }}
            />
            <DefaultLanguage onChangePreview={onChangePreview} />

            <div className="form-group">
              <label htmlFor="title">
                {t("screens:screen.screenRoute.form.code")}
              </label>
              <div className="input-group mb-3">
                <input
                  value={screenData?.digitalReception?.code}
                  name="code"
                  className="form-control"
                  readOnly
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() =>
                      onMutationGenerateScreenCode({
                        variables: { id: screenId },
                      })
                    }
                  >
                    {t("screens:screen.screenRoute.form.code-button")}
                  </button>
                </div>
              </div>
              <small id="languages-help" className="form-text text-muted">
                {t("screens:screen.screenRoute.form.code-helper")}
              </small>
            </div>
          </div>

          <div className="col-lg-8 row">
            <div className="col-12 mb-2">
              <div
                className=" position-absolute p-0"
                style={{ zIndex: 100, width: 600 / (1920 / 1080), height: 600 }}
              />
              {screenId ? (
                <iframe
                  key={`${screenData?.digitalReception?.template}`}
                  ref={previewRef}
                  title={`screen-${screenId}`}
                  className="border border-dark"
                  src={`${screenData?.digitalReception?.previewUrl}?preview=true`}
                  style={{
                    width: 600 / (16 / 9),
                    height: 600,
                  }}
                  frameBorder={0}
                  marginHeight={0}
                  marginWidth={0}
                />
              ) : (
                <div
                  style={{
                    border: "1px solid black",
                    width: 600 / (16 / 9),
                    height: 600,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>{t("screens:screen.screenRoute.iframeMsg")}</div>
                </div>
              )}
            </div>
            <div className="col-12">
              <a
                href={screenData?.digitalReception?.previewUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={clsx("btn btn-primary mr-2", {
                  disabled: !screenId,
                })}
              >
                {t("screens:screen.screenRoute.form.preview")}
              </a>
              <a
                href={screenData?.digitalReception?.playerUrl}
                className={clsx("btn btn-primary", {
                  disabled: !screenId,
                })}
                onClick={(e) => {
                  e.preventDefault();

                  copyToClipboard(e.currentTarget.href);

                  toast.success<string>("Link copied");
                }}
              >
                {t("screens:screen.screenRoute.form.copyURL")}
              </a>
            </div>
          </div>

          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
          </div>
        </form>
      </div>
    </FormProvider>
  );
});

export default Information;
