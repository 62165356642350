import React, { useMemo, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import useToggle from "react-use/lib/useToggle";

import * as yup from "yup";

import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Input from "../../../../components/Input";

import { QUERY_CLIENT_SENDGRID } from "../../../../config/graphql/query";
import {
  CREATE_CLIENT_SENDGRID,
  UPDATE_CLIENT_SENDGRID,
  DELETE_CLIENT_SENDGRID,
} from "../../../../config/graphql/mutation";

import { useClientId } from "../hooks/useClient";

const SendGridClient = React.memo(() => {
  const [show, setShow] = useToggle(false);

  const { t } = useTranslation(["client", "common"]);
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const clientId = useClientId();

  const schema = useMemo(
    () =>
      yup.object().shape({
        title: yup.string().required(t("client:client.sendgrid.yup.title")),
        apiKey: yup.string().required(t("client:client.sendgrid.yup.apikey")),
        senderEmail: yup
          .string()
          .email()
          .required(t("client:client.sendgrid.yup.email")),
        senderName: yup.string().nullable(),
      }),
    [t],
  );

  const methods = useForm<IClientSendGrid>({
    resolver: yupResolver(schema),
    shouldFocusError: false,
    shouldUnregister: true,
    defaultValues: {},
  });

  const { data: sendGridClientData } = useQuery(QUERY_CLIENT_SENDGRID, {
    skip: !id,
    variables: { id },
    onCompleted: ({ sendGridClient }) => methods.reset(sendGridClient),
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const data = sendGridClientData?.sendGridClient;

  const [onUpdate] = useMutation(UPDATE_CLIENT_SENDGRID, {
    refetchQueries: [
      {
        query: QUERY_CLIENT_SENDGRID,
        variables: { id },
        context: {
          headers: {
            ClientId: clientId,
          },
        },
      },
    ],
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const [onCreate] = useMutation(CREATE_CLIENT_SENDGRID, {
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const [onDelete] = useMutation(DELETE_CLIENT_SENDGRID, {
    context: {
      headers: {
        ClientId: clientId,
      },
    },
  });

  const onBeforeDelete = useCallback(() => {
    history.replace(`/sendgrid`);

    return onDelete({ variables: { input: { id, client: clientId } } })
      .then(() => {
        toast.success<string>(t("client:client.information.toast.deleted"));
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );
      });
  }, [history, onDelete, clientId, id, t]);

  const onSubmit = useCallback(
    (values: IClientSendGrid) => {
      if (!clientId) {
        return toast.error<string>(
          t("client:client.sendgrid.error.missingclient"),
        );
      }

      const input = {
        ...(id ? { id } : {}),
        ...(clientId && !id ? { client: clientId } : {}),
        ...values,
      };

      if (id) {
        return onUpdate({ variables: { input } })
          .then(({ data: { updateSendGridClient } }) => {
            methods.reset(updateSendGridClient);
          })
          .then(() => {
            toast.success<string>(t("client:client.information.toast.updated"));
          })
          .catch((error) => {
            toast.error<string>(
              error?.networkError?.result?.errors?.[0]?.message ??
                error?.message,
            );
          });
      }

      return onCreate({ variables: { input } })
        .then(
          ({
            data: {
              addSendGridClient: { id },
            },
          }) => {
            toast.success<string>(t("client:client.information.toast.created"));

            history.replace(`/clients/${clientId}/sendgrid/${id}`);
          },
        )
        .catch((error) => {
          toast.error<string>(
            error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
          );
        });
    },
    [data],
  );

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item active" aria-current="page">
            <Link to="/clients">{t("client:client.token.nav.clients")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/clients/${clientId}`}>
              {data?.client?.title ?? "Client"}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            <Link to={`/clients/${clientId}/sendgrid`}>
              {t("client:client.sendgrid.nav.sendgrid")}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {data?.title ?? "Title"}
          </li>
        </ol>
      </nav>
      <FormProvider {...methods}>
        <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="col-lg-4">
            <div className="form-group">
              <label htmlFor="title">
                {t("client:client.sendgrid.form.title")}
              </label>
              <Input name="title" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="apiKey">
                {t("client:client.sendgrid.form.apikey")}
              </label>
              <Input name="apiKey" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="senderEmail">
                {t("client:client.sendgrid.form.senderemail")}
              </label>
              <Input name="senderEmail" className="form-control" />
            </div>
            <div className="form-group">
              <label htmlFor="senderName">
                {t("client:client.sendgrid.form.sendername")}
              </label>
              <Input name="senderName" className="form-control" />
            </div>
          </div>
          <div className="col-12">
            <input type="submit" className="btn btn-primary" />
            {clientId && (
              <button
                onClick={(e) => {
                  e.preventDefault();

                  setShow(true);
                }}
                className="btn btn-danger ml-3"
              >
                {t("common:delete")}
              </button>
            )}
          </div>
        </form>
      </FormProvider>
      <Modal
        size="sm"
        show={show}
        onHide={setShow}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("client:client.information.modal.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:client.information.modal.title", {
            title: data?.client?.title,
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setShow(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default SendGridClient;
