import React, { useCallback, useMemo } from "react";

import { Link, Switch, NavLink, Redirect, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";

import qs from "query-string";
import dayjs from "dayjs";

import Route from "../../components/Route";

import { useCurrentHasPlan } from "../../components/PlanBlock";

import AccessEventsRoute from "./AccessEvents";
import ActivityDashboard from "./Dashboard";
import CheckedIn from "./CheckedIn";
import useHasRole from "../../lib/hooks/useHasRole";

const ActivityRoute = React.memo(() => {
  const location = useLocation();
  const isAdmin = useHasRole(["ADMIN"]);

  const { t } = useTranslation(["activity"]);

  const { pathname } = useLocation();

  const query = useMemo(
    () =>
      qs.parse(location.search, { parseNumbers: true }) as {
        startDate?: Date;
        endDate?: Date;
      },
    [location.search],
  );

  const isFree = useCurrentHasPlan(["free"]);

  const renderBreadCrumb = useCallback(() => {
    if (pathname.endsWith("activity")) {
      return (
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">{t("activity:nav.activity")}</li>
        </ol>
      );
    }

    if (pathname.endsWith("accessEvents")) {
      return (
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/activity">{t("activity:nav.activity")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("activity:nav.accessEvent", { count: 2 })}
          </li>
        </ol>
      );
    }

    if (pathname.endsWith("export")) {
      return (
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/activity">{t("activity:nav.activity")}</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/activity/accessEvents">
              {t("activity:nav.accessEvent", { count: 2 })}
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("activity:nav.export")}
          </li>
        </ol>
      );
    }

    if (pathname.endsWith("checkedIn")) {
      return (
        <ol className="breadcrumb my-3">
          <li className="breadcrumb-item">
            <Link to="/activity">{t("activity:nav.activity")}</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {t("activity:nav.checkedIn")}
          </li>
        </ol>
      );
    }

    return (
      <ol className="breadcrumb my-3">
        <li className="breadcrumb-item">
          <Link to="/activity">{t("activity:nav.activity")}</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/activity/accessEvents">
            {t("activity:nav.accessEvent", { count: 2 })}
          </Link>
        </li>
        <li className="breadcrumb-item active" aria-current="page">
          {t("activity:nav.accessEvent", { count: 1 })}
        </li>
      </ol>
    );
  }, [pathname, t]);

  return (
    <div className="container-fluid">
      <nav aria-label="breadcrumb">{renderBreadCrumb()}</nav>
      <ul className="nav nav-tabs mb-4">
        <li className="nav-item">
          <NavLink
            exact
            className="nav-link"
            to={{
              pathname: "/activity",
              search: qs.stringify({
                startDate:
                  query.startDate ||
                  dayjs().startOf("month").format("YYYY-MM-DD"),
                endDate: query.endDate || dayjs().format("YYYY-MM-DD"),
              }),
            }}
          >
            {t("activity:tab.dashboard")}
          </NavLink>
        </li>
        {(!isFree || isAdmin) && (
          <>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to={{
                  pathname: "/activity/accessEvents",
                  search: qs.stringify({
                    startDate:
                      query.startDate ||
                      dayjs().startOf("month").format("YYYY-MM-DD"),
                    endDate: query.endDate || dayjs().format("YYYY-MM-DD"),
                    page: 1,
                  }),
                }}
              >
                {t("activity:tab.accessEvents")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink exact className="nav-link" to="/activity/checkedIn">
                {t("activity:tab.checkedIn")}
              </NavLink>
            </li>
          </>
        )}
      </ul>

      <div className="tab-content">
        <Switch>
          <Route.Private exact path="/activity">
            <div className="tab-pane active" role="tabpanel">
              <ActivityDashboard />
            </div>
          </Route.Private>
          {(!isFree || isAdmin) && (
            <>
              <Route.Private path="/activity/accessEvents">
                <div className="tab-pane active" role="tabpanel">
                  <AccessEventsRoute />
                </div>
              </Route.Private>
              <Route.Private exact path="/activity/checkedIn">
                <div className="tab-pane active" role="tabpanel">
                  <CheckedIn />
                </div>
              </Route.Private>
            </>
          )}
          <Redirect to="/activity" />
        </Switch>
      </div>
    </div>
  );
});

export default ActivityRoute;
