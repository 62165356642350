/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useState, useRef } from "react";

import Cropper, { Area } from "react-easy-crop";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import set from "lodash/set";

import { toast } from "react-toastify";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";

import { UPDATE_DIGITAL_RECEPTION_THEME } from "../../../../config/graphql/mutation";
import { QUERY_DIGITAL_RECEPTION_THEME } from "../../../../config/graphql/query";

import { UncontrolledUploadInput } from "../../../../components/FileUpload";

import { upload } from "../../../../lib/hooks/useSocket";
import { getCroppedImg } from "../../../../config/const/canvasUtils";

interface FieldValues extends Pick<IDigitalReceptionTheme, "colors"> {
  images?: {
    hero?: { file: string; filename: string };
    logo?: { file: string; filename: string };
  };
}

interface ImageCropPickerProps {
  name: string;
  value?: { file: string; filename: string };
  aspect?: number;
  allowsEditing?: boolean;
  onChange: (file: any) => void;
}

const readFileAsDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const result = e.target?.result;

      if (!(result && typeof result === "string")) {
        return reject(new Error("Failed to read the file."));
      }

      return resolve(result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

const ImageCropPicker = (props: ImageCropPickerProps) => {
  const { name, value, allowsEditing = true, aspect, onChange } = props;

  const { t } = useTranslation(["screens", "common"]);

  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();

  const [image, setImage] = useState<{ file: File; data: string }>();

  const onSubmit = useCallback(async () => {
    if (!image) {
      return;
    }

    if (!croppedAreaPixels) {
      return;
    }

    const croppedImage = await getCroppedImg(image?.data, croppedAreaPixels, 0);

    setImage(undefined);

    onChange({ file: croppedImage, filename: image.file.name });
  }, [croppedAreaPixels, image, onChange]);

  return (
    <>
      <UncontrolledUploadInput
        multiple={false}
        name={name}
        onChange={(file) => {
          if (allowsEditing) {
            return readFileAsDataURL(file).then((data) =>
              setImage({ data, file }),
            );
          }

          return readFileAsDataURL(file).then((data) =>
            onChange({ file: data, filename: file.name }),
          );
        }}
        dropZoneOptions={{
          accept: {
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
          },
          maxFiles: 1,
          maxSize: 3670016,
        }}
      />
      <Modal show={!!image} centered>
        <Modal.Header>
          <Modal.Title>{t("screens:screen.theme.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 500,
            }}
          >
            <Cropper
              image={image?.data}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onZoomChange={setZoom}
              onCropChange={setCrop}
              onCropComplete={(_croppedArea, croppedAreaPixels) =>
                setCroppedAreaPixels(croppedAreaPixels)
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setImage(undefined)}>
            {t("common:cancel")}
          </Button>
          <Button onClick={onSubmit}>{t("common:submit")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

interface ColorPickerProps {
  value?: string;
  label?: string;
  onChange: (value: string) => void;
}

const ColorPicker = (props: ColorPickerProps) => {
  const { value = "#000000", label, onChange } = props;

  const [visible, setVisible] = useState(false);

  return (
    <div className="d-flex flex-column">
      <Button onClick={() => setVisible(true)}>{label}</Button>
      <div className="d-flex flex-row align-items-center my-3">
        <label className="mb-0 mr-3">{`Hex: ${value}`}</label>
        <div
          style={{
            backgroundColor: value,
            width: "1.5rem",
            height: "1.5rem",
            border: ".1rem solid",
          }}
        />
      </div>
      {visible && (
        <div className="position-absolute z-index-2">
          <div
            className="position-fixed top-0 bottom-0 left-0 right-0"
            onClick={() => setVisible(false)}
          />
          <SketchPicker
            color={value}
            onChangeComplete={({ hex }) => {
              onChange(hex);
            }}
          />
        </div>
      )}
    </div>
  );
};

const ThemeRoute = () => {
  const methods = useForm<FieldValues>({ shouldUnregister: true });

  const { screenId } = useParams<{ screenId: string }>();

  const previewRef = useRef<HTMLIFrameElement>(null);

  const { t } = useTranslation(["screens", "common"]);

  const postMessage = useCallback((message: string, targetOrigin = "*") => {
    return previewRef?.current?.contentWindow?.postMessage(
      message,
      targetOrigin,
    );
  }, []);

  const onChangePreview = useCallback(
    (name: string, value: any) => {
      const data = {};

      set(data, ["digitalReception", "theme", ...name.split(".")], value);

      const message = JSON.stringify({
        action: "update-digitalReception",
        data,
      });

      return postMessage(message);
    },
    [postMessage],
  );

  const { data: screenData } = useQuery<{
    digitalReception: IDigitalReception;
  }>(QUERY_DIGITAL_RECEPTION_THEME, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onCompleted: ({ digitalReception: { theme } }) => {
      methods.reset({
        colors: theme?.colors,
      });
    },
  });

  const [onUpdate] = useMutation(UPDATE_DIGITAL_RECEPTION_THEME, {
    refetchQueries: [
      {
        query: QUERY_DIGITAL_RECEPTION_THEME,
        variables: { id: screenId },
      },
    ],
  });

  const onUpload = useCallback(async (images: FieldValues["images"]) => {
    let input: { hero?: string; logo?: string } = {};

    if (images?.hero) {
      await upload([images.hero]).then((r) => {
        input = {
          ...input,
          hero: r[0].id,
        };
      });
    }

    if (images?.logo) {
      await upload([images.logo]).then((r) => {
        input = {
          ...input,
          logo: r[0].id,
        };
      });
    }

    return input;
  }, []);

  const onSubmit = useCallback(
    async ({ images, ...rest }: FieldValues) => {
      const uploadedImages = await onUpload(images);

      const theme = {
        ...rest,
        images: uploadedImages,
      };

      onUpdate({
        variables: {
          input: {
            id: screenId,
            theme,
          },
        },
      }).then(() => {
        toast.success<string>(t("screens:screen.screenRoute.toast.updated"));

        const previewEl = document.querySelector<HTMLIFrameElement>("#preview");

        if (previewEl) {
          previewEl.src += "";
        }
      });
    },
    [onUpdate, onUpload, t, screenId],
  );

  return (
    <FormProvider {...methods}>
      <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="col-lg-4">
          {/* <div className="form-group mb-4">
            <label htmlFor="welcomeMessage">
              {t("screens:screen.theme.welcomeMessage")}
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  {language.toUpperCase()}
                </span>
              </div>
              <input
                {...methods.register("welcomeMessage", {
                  onChange: ({ target: { value } }) => {
                    onChangePreview(`welcomeMessage.${language}`, value);
                  },
                })}
                className="form-control"
              />
            </div>
          </div> */}

          <Controller
            name="colors.primary"
            render={({ field: { value, name, onChange } }) => (
              <div className="form-group">
                <ColorPicker
                  label={t("screens:screen.theme.color.primary")}
                  value={typeof value === "string" ? value : "#007bff"}
                  onChange={(value) => {
                    onChange(value);

                    onChangePreview(name, value);
                  }}
                />
              </div>
            )}
          />
          <Controller
            name="colors.background"
            render={({ field: { value, name, onChange } }) => (
              <div className="form-group">
                <ColorPicker
                  label={t("screens:screen.theme.color.background")}
                  value={typeof value === "string" ? value : "#ffffff"}
                  onChange={(value) => {
                    onChange(value);

                    onChangePreview(name, value);
                  }}
                />
              </div>
            )}
          />
          <Controller
            name="colors.textPrimary"
            render={({ field: { value, name, onChange } }) => (
              <div className="form-group">
                <ColorPicker
                  label={t("screens:screen.theme.color.primaryText")}
                  value={typeof value === "string" ? value : "#212529"}
                  onChange={(value) => {
                    onChange(value);

                    onChangePreview(name, value);
                  }}
                />
              </div>
            )}
          />
          <Controller
            name="images.hero"
            render={({ field: { name, value, onChange } }) => (
              <div className="form-group">
                <label htmlFor={name}>
                  {t("screens:screen.theme.image.hero")}
                </label>
                <ImageCropPicker
                  name={name}
                  aspect={1080 / 545}
                  value={value}
                  onChange={(fileBase64) => {
                    onChange(fileBase64);

                    onChangePreview(`${name}.absolutePath`, fileBase64.file);
                  }}
                />
                <small className="form-text text-muted">
                  {t("screens:screen.theme.image.description")}
                </small>
              </div>
            )}
          />
          <Controller
            name="images.logo"
            render={({ field: { name, value, onChange } }) => (
              <div className="form-group">
                <label htmlFor={name}>
                  {t("screens:screen.theme.image.logo")}
                </label>
                <ImageCropPicker
                  name={name}
                  allowsEditing={false}
                  value={value}
                  onChange={(fileBase64) => {
                    onChange(fileBase64);

                    onChangePreview(`${name}.absolutePath`, fileBase64.file);
                  }}
                />
                <small className="form-text text-muted">
                  {t("screens:screen.theme.image.description")}
                </small>
              </div>
            )}
          />
        </div>
        <div className="col-lg-8 row">
          <div className="col-12 mb-2">
            <div
              className=" position-absolute p-0"
              style={{ zIndex: 100, width: 600 / (1920 / 1080), height: 600 }}
            />
            <iframe
              id="preview"
              ref={previewRef}
              title={`screen-${screenId}`}
              className="border border-dark"
              src={`${screenData?.digitalReception?.previewUrl}?theme=true&preview=true`}
              style={{
                width: 600 / (1920 / 1080),
                height: 600,
              }}
              frameBorder={0}
              marginHeight={0}
              marginWidth={0}
            />
          </div>
        </div>
        <div className="col-12">
          <input type="submit" className="btn btn-primary" />
        </div>
      </form>
    </FormProvider>
  );
};

export default ThemeRoute;
