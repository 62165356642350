/* eslint-disable no-restricted-syntax */
import { useCallback, useRef, useEffect } from "react";

import {
  useForm,
  useWatch,
  Controller,
  FieldValues,
  FormProvider,
  ControllerFieldState,
  ControllerRenderProps,
} from "react-hook-form";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";

import get from "lodash/get";
import set from "lodash/set";

import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { isValidPhoneNumber } from "libphonenumber-js/min";

import { QUERY_DIGITAL_RECEPTION_SETTINGS } from "../../../../../config/graphql/query";
import { UPDATE_DIGITAL_RECEPTION_SETTINGS } from "../../../../../config/graphql/mutation";

import { useCurrentLanguage } from "../../../../../lib/hooks/useCurrentLanguage";
import Input from "../../../../../components/Input";
import { useCurrentHasPlan } from "../../../../../components/PlanBlock";

const FormCheckbox = (props: {
  form: {
    field: ControllerRenderProps<FieldValues, `${string}.enabled`>;
    fieldState: ControllerFieldState;
  };
  label: React.ReactNode;
  onChangePreview: (name: string, value: any) => any;
}) => {
  const {
    form: {
      field: { value, name, onChange },
      fieldState: { error },
    },
    label,
    onChangePreview,
  } = props;

  return (
    <div className="form-group form-check mb-1">
      <input
        id={name}
        type="checkbox"
        className="form-check-input"
        checked={value}
        onChange={(value) => {
          onChange(value);
          onChangePreview(name, value.target.checked);
        }}
      />
      <label className="form-check-label user-select-none" htmlFor={name}>
        {label}
      </label>
      {!!error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  );
};

const HomeButtonText = (props: {
  name: string;
  onChangePreview: (name: string, value: any) => any;
}) => {
  const { name, onChangePreview } = props;

  const { t } = useTranslation(["screens"]);

  const language = useCurrentLanguage() as "en" | "nl";

  const enabled = useWatch({ name: `${name}.enabled` });

  if (!enabled) {
    return null;
  }

  return (
    <div className="form-group mb-4">
      <label htmlFor={`${name}.text`}>
        {t("screens:screen.settings.layout.text")}
      </label>
      <div className="input-group">
        <div className="input-group-prepend">
          <span className="input-group-text">{language.toUpperCase()}</span>
        </div>
        <Controller
          name={`${name}.text`}
          render={({ field: { onChange, ...props } }) => (
            <input
              onChange={(value) => {
                onChange(value);
                onChangePreview(`${name}.text.${language}`, value.target.value);
              }}
              {...props}
              className="form-control"
            />
          )}
        />
      </div>
    </div>
  );
};

const HomeButtonController = (props: {
  name: string;
  label: string;
  onChangePreview: (name: string, value: any) => void | undefined;
}) => {
  const { name, label, onChangePreview } = props;

  return (
    <div style={{ marginBottom: "2.25rem" }}>
      <Controller
        name={`${name}.enabled`}
        render={(form) => (
          <FormCheckbox
            form={form}
            label={label}
            onChangePreview={onChangePreview}
          />
        )}
      />
      <HomeButtonText name={name} onChangePreview={onChangePreview} />
    </div>
  );
};

interface FormValues extends Omit<IDigitalReceptionSettings, "layout"> {
  layout: IDigitalReceptionLayout<string>;
}

const GeneralSettings = () => {
  const methods = useForm<FormValues>({
    shouldUnregister: false,
  });

  const { screenId } = useParams<{ screenId: string }>();

  const language = useCurrentLanguage() as "en" | "nl";

  const isFree = useCurrentHasPlan(["free"]);

  const previewRef = useRef<HTMLIFrameElement>(null);

  const { t } = useTranslation(["screens", "common"]);

  const { data: screenData } = useQuery<{
    digitalReception: IDigitalReception;
  }>(QUERY_DIGITAL_RECEPTION_SETTINGS, {
    skip: !screenId,
    variables: {
      id: screenId,
    },
    onCompleted: ({ digitalReception: { settings } }) => {
      if (!settings) {
        return;
      }

      const { layout, ...rest } = settings;

      const layoutForReset: IDigitalReceptionLayout<string> = {};

      if (layout) {
        for (const [key, value] of Object.entries(layout)) {
          if (key !== "__typename") {
            if (key !== "welcomeMessage") {
              layoutForReset[
                key as keyof Omit<
                  IDigitalReceptionLayout<string>,
                  "welcomeMessage"
                >
              ] = {
                enabled: get(value, "enabled"),
                text: get(value, `text.${language}`),
              };
            } else {
              layoutForReset[key] = get(value, language);
            }
          }
        }
      }

      methods.reset({ layout: layoutForReset, ...rest });
    },
  });

  const postMessage = useCallback(
    (message: string, targetOrigin = "*") => {
      return previewRef?.current?.contentWindow?.postMessage(
        message,
        targetOrigin,
      );
    },
    [previewRef],
  );

  const onChangePreview = useCallback(
    (name: string, value: any) => {
      const data = {};

      set(data, ["digitalReception", "settings", ...name.split(".")], value);

      const message = JSON.stringify({
        action: "update-digitalReception",
        data,
      });

      return postMessage(message);
    },
    [postMessage],
  );

  useEffect(() => {
    const layout = get(screenData, "digitalReception.settings.layout");

    if (!layout) {
      return;
    }

    for (const [key, value] of Object.entries(
      layout as IDigitalReceptionLayout<{ en?: string; nl?: string }>,
    )) {
      if (key !== "__typename") {
        if (key !== "welcomeMessage") {
          methods.setValue(
            `layout.${
              key as keyof Omit<
                IDigitalReceptionLayout<string>,
                "welcomeMessage"
              >
            }.text`,
            get(value, `text.${language}`),
          );
        } else {
          methods.setValue(`layout.${key}`, get(value, language));
        }
      }
    }
  }, [language]);

  const [onUpdate] = useMutation(UPDATE_DIGITAL_RECEPTION_SETTINGS, {
    refetchQueries: [
      {
        query: QUERY_DIGITAL_RECEPTION_SETTINGS,
        variables: { id: screenId },
      },
    ],
  });

  const onSubmit = useCallback(
    async (settings: FormValues) => {
      const { layout, support, receptionistPhone, dispenser } = settings;

      const nextLayout: IDigitalReceptionLayout<{
        en?: string;
        nl?: string;
      }> = {};

      if (layout) {
        for (const [key, value] of Object.entries(layout)) {
          if (key !== "welcomeMessage") {
            nextLayout[key as keyof IDigitalReceptionLayout<string>] = {
              enabled: get(value, "enabled"),
              text: { [language]: get(value, "text") },
            };
          } else {
            nextLayout[key] = { [language]: value };
          }
        }
      }

      onUpdate({
        variables: {
          input: {
            id: screenId,
            settings: {
              layout: nextLayout,
              support: {
                email: support?.email || null,
              },
              receptionistPhone: {
                primary: receptionistPhone?.primary || null,
                backup: receptionistPhone?.backup || null,
              },
              dispenser: {
                id: dispenser.id || null,
                socketUrl: dispenser.socketUrl || null,
              },
            },
          },
        },
      }).then(() => {
        toast.success<string>(t("screens:screen.screenRoute.toast.updated"));

        const previewEl = document.querySelector<HTMLIFrameElement>("#preview");

        if (previewEl) {
          previewEl.src += "";
        }
      });
    },
    [onUpdate, t, screenId, language],
  );

  return (
    <FormProvider {...methods}>
      <form className="row" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="col-lg-4">
          <h6>{t("screens:screen.settings.layout.title")}</h6>
          <div className="form-group mb-4">
            <label htmlFor="welcomeMessage">
              {t("screens:screen.settings.layout.welcomeMessage")}
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  {language.toUpperCase()}
                </span>
              </div>
              <input
                {...methods.register("layout.welcomeMessage", {
                  onChange: ({ target: { value } }) => {
                    onChangePreview(`layout.welcomeMessage.${language}`, value);
                  },
                })}
                className="form-control"
              />
            </div>
          </div>
          <HomeButtonController
            name="layout.checkin"
            label={t(`screens:screen.settings.layout.checkin`)}
            onChangePreview={onChangePreview}
          />
          <HomeButtonController
            name="layout.checkout"
            label={t(`screens:screen.settings.layout.checkout`)}
            onChangePreview={onChangePreview}
          />
          <HomeButtonController
            name="layout.package"
            label={t(`screens:screen.settings.layout.package`)}
            onChangePreview={onChangePreview}
          />
          <HomeButtonController
            name="layout.food"
            label={t(`screens:screen.settings.layout.food`)}
            onChangePreview={onChangePreview}
          />
          {!isFree && (
            <HomeButtonController
              name="layout.callReception"
              label={t(`screens:screen.settings.layout.callReception`)}
              onChangePreview={onChangePreview}
            />
          )}
        </div>
        <div className="col-lg-4 row">
          {!isFree && (
            <div className="form-group mb-4">
              <h6>{t("screens:screen.settings.receptionistPhone.title")}</h6>
              <div className="form-group">
                <label htmlFor="phone">
                  {t("screens:screen.settings.receptionistPhone.primaryPhone")}
                </label>
                <Input
                  name="receptionistPhone.primary"
                  className="form-control"
                  rules={{
                    validate: {
                      invalid: (value) => {
                        if (!value) {
                          return true;
                        }
                        return (
                          isValidPhoneNumber(value) ||
                          t("employee:input.error.phone.format")
                        );
                      },
                    },
                  }}
                />
                <small className="form-text text-muted small">
                  {t("employee:input.label.phone-help")}
                </small>
              </div>
              <div className="form-group mt-5 mb-4">
                <h6>{t("screens:screen.settings.dispenser.title")}</h6>
                <div className="form-group">
                  <label htmlFor="dispenserId">
                    {t("screens:screen.settings.dispenser.id")}
                  </label>
                  <Input name="dispenser.id" className="form-control" />
                </div>
                <div className="form-group">
                  <label htmlFor="dispenserSocketUrl">
                    {t("screens:screen.settings.dispenser.socketUrl")}
                  </label>
                  <Input name="dispenser.socketUrl" className="form-control" />
                </div>
              </div>
              <div className="form-group mt-5 mb-4">
                <h6>{t("screens:screen.settings.support.title")}</h6>
                <div className="form-group">
                  <label htmlFor="supportEmail">
                    {t("screens:screen.settings.support.email")}
                  </label>
                  <Input
                    name="support.email"
                    autoComplete="email"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-4 mt-5">
          <div className="col-12 mb-2">
            <div
              className=" position-absolute p-0"
              style={{ zIndex: 100, width: 600 / (1920 / 1080), height: 600 }}
            />
            <iframe
              id="preview"
              ref={previewRef}
              title={`screen-${screenId}`}
              className="border border-dark"
              src={`${screenData?.digitalReception?.previewUrl}?preview=true`}
              style={{
                width: 600 / (1920 / 1080),
                height: 600,
              }}
              frameBorder={0}
              marginHeight={0}
              marginWidth={0}
            />
          </div>
        </div>
        <div className="col-12">
          <input type="submit" className="btn btn-primary" />
        </div>
      </form>
    </FormProvider>
  );
};

export default GeneralSettings;
