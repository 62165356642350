import React from "react";

import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import ClientPage, { ClientCreatePage } from "./Client";
import ClientsRoute from "./Clients";

import Token from "./Client/Token/Token";

import TwilioClient from "./Client/Twilio/TwilioClient";
import SendGridClient from "./Client/SendGrid/Client";

import OutlookIntegration from "./Client/Outlook/Integration";

import EmailTemplate from "./Client/Email/EmailTemplate";
import MessageTemplate from "./Client/Message/MessageTemplate";

export const CurrentClientRoute = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/sendgrid-email-templates/new`}>
        <EmailTemplate />
      </Route>
      <Route exact path={`${path}/sendgrid-email-templates/:id`}>
        <EmailTemplate />
      </Route>
      <Route exact path={`${path}/twilio-message-templates/new`}>
        <MessageTemplate />
      </Route>
      <Route exact path={`${path}/twilio-message-templates/:id`}>
        <MessageTemplate />
      </Route>
      <Route exact path={`${path}/sendgrid/new`}>
        <SendGridClient />
      </Route>
      <Route exact path={`${path}/token/new`}>
        <Token />
      </Route>
      <Route exact path={`${path}/twilio/new`}>
        <TwilioClient />
      </Route>
      <Route path={`${path}/twilio/:id`}>
        <TwilioClient />
      </Route>
      <Route exact path={`${path}/integrations/outlook/:subscriptionId`}>
        <OutlookIntegration />
      </Route>
      <Route exact path={`${path}/token/:id`}>
        <Token />
      </Route>
      <Route exact path={`${path}/sendgrid/:id`}>
        <SendGridClient />
      </Route>
      <Route path={path}>
        <ClientPage />
      </Route>
    </Switch>
  );
});

const ClientsRouter = React.memo(() => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ClientsRoute />
      </Route>
      <Route exact path={`${path}/new`}>
        <ClientCreatePage />
      </Route>
      <Route exact path={`${path}/:clientId/sendgrid-email-templates/new`}>
        <EmailTemplate />
      </Route>
      <Route exact path={`${path}/:clientId/sendgrid-email-templates/:id`}>
        <EmailTemplate />
      </Route>
      <Route exact path={`${path}/:clientId/twilio-message-templates/new`}>
        <MessageTemplate />
      </Route>
      <Route exact path={`${path}/:clientId/twilio-message-templates/:id`}>
        <MessageTemplate />
      </Route>
      <Route exact path={`${path}/:clientId/sendgrid/new`}>
        <SendGridClient />
      </Route>
      <Route exact path={`${path}/:clientId/token/new`}>
        <Token />
      </Route>
      <Route exact path={`${path}/:clientId/twilio/new`}>
        <TwilioClient />
      </Route>
      <Route path={`${path}/:clientId/twilio/:id`}>
        <TwilioClient />
      </Route>
      <Route
        exact
        path={`${path}/:clientId/integrations/outlook/:subscriptionId`}
      >
        <OutlookIntegration />
      </Route>
      <Route exact path={`${path}/:clientId/token/:id`}>
        <Token />
      </Route>
      <Route exact path={`${path}/:clientId/sendgrid/:id`}>
        <SendGridClient />
      </Route>
      <Route path={`${path}/:clientId`}>
        <ClientPage />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
});

export default ClientsRouter;
