import React from "react";

import clsx from "clsx";

import { ToastContainer } from "react-toastify";

import { useQuery } from "@apollo/client";
import {
  BrowserRouter,
  Switch,
  Redirect,
  Route as RegularRoute,
} from "react-router-dom";

import Route from "../components/Route";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

import { AuthProvider } from "../context/Auth";
import { ApolloProvider } from "../context/Apollo";
import { ClientProvider } from "../context/Client";
import { SidebarProvider, useSidebarState } from "../context/Sidebar";

import { QUERY_AUTH } from "../config/graphql/query";

import * as Page from "../pages";
import { CurrentClientRoute } from "../pages/Clients";

import useHasRole from "../lib/hooks/useHasRole";

const PublicRouter = React.memo(() => (
  <div className="d-flex" id="wrapper">
    <div className="container-fluid">
      <Switch>
        <Route.Public exact path="/login">
          <Page.Login />
        </Route.Public>
        <Route.Public exact path="/forgot-password">
          <Page.ForgotPassword />
        </Route.Public>
        <Route.Public exact path="/verify-2fa">
          <Page.Verify2fa />
        </Route.Public>
        <RegularRoute exact path="/reset-password">
          <Page.ResetPassword />
        </RegularRoute>
        <RegularRoute exact path="/verify-email">
          <Page.EmailVerification />
        </RegularRoute>
      </Switch>
    </div>
  </div>
));

const Wrapper = React.memo<{ children?: React.ReactNode }>((props) => {
  const { children } = props;

  const { visible } = useSidebarState();

  return (
    <div
      id="wrapper"
      className={clsx("d-flex", {
        toggled: visible,
      })}
    >
      {children}
    </div>
  );
});

const PrivateRouter = React.memo(() => {
  const { data } = useQuery<{ clientId: string }>(QUERY_AUTH);

  const clientId = data?.clientId;

  const isActivityRole = useHasRole(["ACTIVITY"]);

  return (
    <SidebarProvider>
      <Wrapper>
        <Sidebar />
        <div
          key={clientId}
          id="page-content-wrapper"
          className="d-flex flex-column"
        >
          <Navbar />

          <Switch>
            <Route.Private exact path="/">
              <Redirect to={isActivityRole ? "/activity" : "/entity"} />
              {/* <Page.Dashboard /> */}
            </Route.Private>
            <Route.Private path="/entity">
              <Page.Entities />
            </Route.Private>
            <Route.Private path="/company">
              <Page.Companies />
            </Route.Private>
            <Route.Private path="/deceased">
              <Page.Deceased />
            </Route.Private>
            <Route.Private path="/profile">
              <Page.Profile />
            </Route.Private>

            <Route.Role
              path="/client"
              roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            >
              <CurrentClientRoute />
            </Route.Role>
            <Route.Role
              roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
              path="/users"
            >
              <Page.Users />
            </Route.Role>

            <Route.Role
              path="/employees"
              roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            >
              <Page.Employees />
            </Route.Role>

            <Route.Role
              path="/visitors"
              roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            >
              <Page.Visitors />
            </Route.Role>

            <Route.Role
              path="/meetings"
              roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            >
              <Page.Meetings />
            </Route.Role>

            <Route.Role
              path="/activity"
              roles={["ADMIN", "CLIENT_ADMIN", "CLIENT", "ACTIVITY"]}
            >
              <Page.Activity />
            </Route.Role>

            <Route.Role
              path="/digital-receptions"
              roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            >
              <Page.DigitalReceptions />
            </Route.Role>

            <Route.Role
              path="/nameboards"
              roles={["ADMIN", "CLIENT_ADMIN", "CLIENT"]}
            >
              <Page.Nameboards />
            </Route.Role>

            <Route.Private path="/wayfinding">
              <Page.Wayfinding />
            </Route.Private>

            <Route.Private path="/minew-device">
              <Page.MinewDevice />
            </Route.Private>

            <Route.Private path="/floorInfo">
              <Page.FloorInfos />
            </Route.Private>
            <Route.Private path="/postbox">
              <Page.Postboxes />
            </Route.Private>

            <Route.Role path="/toro/visitors" roles={["ADMIN"]}>
              <Page.VisitorLog />
            </Route.Role>
            <Route.Role path="/toro/meetings" roles={["ADMIN"]}>
              <Page.ToroMeetings />
            </Route.Role>

            <Route.Role path="/clients" roles={["ADMIN"]}>
              <Page.Clients />
            </Route.Role>
            <Route.Role path="/visionects" roles={["ADMIN"]}>
              <Page.Visionects />
            </Route.Role>
            <Route.Role path="/minews" roles={["ADMIN"]}>
              <Page.Minews />
            </Route.Role>
            <Route.Role path="/logs" roles={["ADMIN"]}>
              <Page.Logs />
            </Route.Role>
            <Route.Role path="/errorLogs" roles={["ADMIN"]}>
              <Page.ErrorsLog />
            </Route.Role>
            <Route.Role path="/assets" roles={["ADMIN"]}>
              <Page.Assets />
            </Route.Role>
            <Redirect to="/" />
          </Switch>
        </div>
      </Wrapper>
    </SidebarProvider>
  );
});

const Root: React.FunctionComponent = () => {
  return (
    <>
      <BrowserRouter>
        <ApolloProvider>
          <AuthProvider>
            <Switch>
              <RegularRoute
                exact
                path={[
                  "/login",
                  "/forgot-password",
                  "/reset-password",
                  "/verify-email",
                  "/verify-2fa",
                ]}
              >
                <PublicRouter />
              </RegularRoute>
              <Route.Private path="/">
                <ClientProvider>
                  <PrivateRouter />
                </ClientProvider>
              </Route.Private>
            </Switch>
          </AuthProvider>
        </ApolloProvider>
      </BrowserRouter>
      <ToastContainer position="bottom-right" />
    </>
  );
};

export default Root;
