import React, { useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import useToggle from "react-use/lib/useToggle";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useRouteMatch, Link, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import { QUERY_CLIENT_SENDGRID_LIST } from "../../../../config/graphql/query";
import { DELETE_CLIENT_SENDGRID } from "../../../../config/graphql/mutation";

import Table from "../../../../components/Table";
import { useClientId } from "../hooks/useClient";

export const useQuerySendGridClients = () => {
  const clientId = useClientId();

  return useQuery<{ sendGridClients: IClientSendGrid[] }>(
    QUERY_CLIENT_SENDGRID_LIST,
    {
      context: {
        headers: {
          ClientId: clientId,
        },
      },
      fetchPolicy: "network-only",
    },
  );
};

const TableRow = ({
  item,
  index,
}: {
  item: IClientSendGrid;
  index: number;
}) => {
  const { url } = useRouteMatch();

  const clientId = useClientId();

  const history = useHistory();

  const { t } = useTranslation(["client", "common"]);

  const { id, title, apiKey, senderEmail, senderName } = item;

  const [visible, setVisible] = useToggle(false);

  const [onDelete] = useMutation(DELETE_CLIENT_SENDGRID, {
    variables: { input: { id } },
    context: {
      headers: {
        ClientId: clientId,
      },
    },
    refetchQueries: [
      {
        query: QUERY_CLIENT_SENDGRID_LIST,
        context: {
          headers: {
            ClientId: clientId,
          },
        },
      },
    ],
  });

  const onBeforeDelete = () => {
    onDelete()
      .then(() => {
        toast.success<string>(t("client:client.sendgrid.toast.deleted"));
        history.replace(`/clients/${clientId}/sendgrid`);
      })
      .catch((error) => {
        toast.error<string>(
          error?.networkError?.result?.errors?.[0]?.message ?? error?.message,
        );

        history.replace(`/clients/${clientId}/sendgrid`);
      });

    setVisible(false);
  };

  return (
    <>
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{title}</td>
        <td>{`${apiKey.slice(0, 10)}...`}</td>
        <td>
          {senderEmail}
          {senderName ? ` - ${senderName}` : ""}
        </td>
        <td>
          <div className="d-flex">
            <Button
              size="sm"
              variant="danger"
              className="mr-2"
              onClick={() => setVisible(true)}
            >
              {t("common:delete")}
            </Button>
            <Link to={`${url}/${id}`}>
              <Button size="sm" variant="primary">
                {t("common:view")}
              </Button>
            </Link>
          </div>
        </td>
      </tr>
      <Modal
        size="sm"
        show={visible}
        onHide={setVisible}
        centered
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("client:client.sendgrid.modal.title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("client:client.sendgrid.modal.body", { id })}
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={() => setVisible(false)}>
            {t("common:cancel")}
          </Button>
          <Button size="sm" variant="danger" onClick={onBeforeDelete}>
            {t("common:delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const SendGridClients = React.memo(() => {
  const match = useRouteMatch();

  const { t } = useTranslation(["client", "common"]);

  const { data } = useQuerySendGridClients();

  const sendGridClients = data?.sendGridClients ?? [];

  const renderClientSendGrid = useCallback(
    (item: IClientSendGrid, index: number) => (
      <TableRow key={item.id} item={item} index={index} />
    ),
    [],
  );

  return (
    <>
      <div className="tab-content">
        {!(Array.isArray(sendGridClients) && sendGridClients.length > 0) && (
          <div className="d-flex justify-content-end align-items-center my-4">
            <div>
              <Link
                to={`${match.url}/new`}
                type="button"
                className="btn btn-primary"
              >
                {t("client:client.tokens.button.add")}
              </Link>
            </div>
          </div>
        )}

        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{t("client:client.tokens.th.title")}</th>
              <th scope="col">{t("client:client.sendgrid.th.apikey")}</th>
              <th scope="col">{t("client:client.sendgrid.th.senderName")}</th>
              <th scope="col">{t("client:client.tokens.th.actions")}</th>
            </tr>
          </thead>
          <tbody>{sendGridClients.map(renderClientSendGrid)}</tbody>
        </Table>
      </div>
    </>
  );
});

export default SendGridClients;
